<template>
  <div>
    <v-card>
      <div class="d-flex align-center justify-space-between">
        <v-card-title style="line-height: 1.45">
          {{ topicData.name }}
        </v-card-title>
        <div class="mr-4">
          <v-chip
            :color="getTopicStatusColor(topicData.status_label)"
            small
            :class="`v-chip-light-bg ${getTopicStatusColor(topicData.status_label)}--text`"
          >
            {{ $t(getTopicStatus(topicData.status_label)) }}
          </v-chip>
        </div>
      </div>
      <v-card-text>
        <v-btn
          small
          outlined
          class="v-chip-light-bg primary--text"
          @click="showQrCode = true"
        >
          {{ $t('ViewQRCode') }}
        </v-btn>
      </v-card-text>

      <v-card-text>
        <strong>{{ $t('Speaker') }}</strong>: {{ topicData.speaker }}
      </v-card-text>
      <v-card-text>
        <strong>{{ $t('Time') }}</strong>: {{
          enrichTopicTime(
            formatTimestampToDatetime(topicData.start_time, 'DD/MM/YYYY HH:mm'),
            formatTimestampToDatetime(topicData.end_time, 'DD/MM/YYYY HH:mm')
          )
        }}
      </v-card-text>
      <v-card-text v-if="!!topicData.image_1_url || !!topicData.image_2_url">
        <div class="d-flex justify-space-between mb-4">
          <v-img
            v-if="!!topicData.image_1_url"
            :style="!!topicData.image_2_url ? 'width: 40%; margin-right: 8px' : 'width: 100%'"
            :src="topicData.image_1_url"
            :alt="`image ${topicData.image_1}`"
          />
          <v-img
            v-if="!!topicData.image_2_url"
            :style="!!topicData.image_1_url ? 'width: 40%; margin-left: 8px' : 'width: 100%'"
            :src="topicData.image_2_url"
            :alt="`image ${topicData.image_2_url}`"
          />
        </div>
      </v-card-text>
      <v-card-text>
        <p style="white-space: pre-line">
          <strong>{{ $t('Description') }}</strong>:<br /> {{ topicData.description }}
        </p>
      </v-card-text>

      <v-card-text>
        <strong>{{ $t('Participants') }}</strong>: {{ topicData.participants }} / {{ topicData.max_seat }}
      </v-card-text>

      <v-btn
        color="success"
        plain
        class="mb-4"
        @click="handleShowCheckinList"
      >
        {{ $t('CheckinList') }}
      </v-btn>

      <v-card-text>
        <v-row v-if="needApproval">
          <v-col
            cols="12"
            md="6"
          >
            <v-btn
              color="primary"
              block
              :loading="isApproving"
              @click="isShowDialogApproveTopic=true"
            >
              {{ $t('Approve') }}
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="py-0"
          >
            <v-btn
              color="error"
              outlined
              block
              :loading="isRejecting"
              @click="isShowDialogRejectTopic=true"
            >
              {{ $t('Reject') }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-btn
              color="error"
              block
              class="mb-4"
              :to="{name: 'staff-skill-share-edit', params: {id: `${topicData.id_topic}`}}"
            >
              {{ $t('Edit') }}
            </v-btn>
            <v-btn
              outlined
              color="warning"
              block
              @click="$router.back()"
            >
              {{ $t('Back') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="showCheckedinList"
      max-width="500px"
    >
      <v-card class="triangle-card mt-4 ">
        <v-card-title class="page-title">
          {{ $t('CheckinList') }}
        </v-card-title>
        <v-card-text v-if="!!topicData.image_1_url || !!topicData.image_2_url">
          <div class="d-flex justify-space-between">
            <v-img
              v-if="!!topicData.image_1_url"
              :style="!!topicData.image_2_url ? 'width: 40%; margin-right: 8px' : 'width: 100%'"
              :src="topicData.image_1_url"
              :alt="`image ${topicData.image_1}`"
            />
            <v-img
              v-if="!!topicData.image_2_url"
              :style="!!topicData.image_1_url ? 'width: 40%; margin-left: 8px' : 'width: 100%'"
              :src="topicData.image_2_url"
              :alt="`image ${topicData.image_2_url}`"
            />
          </div>
        </v-card-text>
        <template>
          <v-list
            v-if="checkedInList.length > 0"
            two-line
          >
            <template v-for="(item, index) in checkedInList">
              <v-list-item
                :key="item.id_user"
              >
                <v-list-item-avatar>
                  <v-img :src="item.face_image_url"></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>
                    <span class="font-weight-semibold primary--text">
                      {{ item.full_name }}
                    </span>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <span class="text--primary ">
                      Checkin
                    </span>
                    {{ formatTimestampToDatetime(item.checkIn_at) }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider
                v-if="index < checkedInList.length - 1"
                :key="`divider-${item.id_user}`"
                :inset="item.inset"
              ></v-divider>
            </template>
          </v-list>
          <div
            v-else
            class="text-center pb-2"
          >
            {{ $t("NoData") }}
          </div>
        </template>
        <v-card-actions>
          <v-row>
            <div class="v-col col-12">
              <v-btn
                block
                outlined
                @click="showCheckedinList = false"
              >
                {{ $t('Close') }}
              </v-btn>
            </div>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showQrCode"
      max-width="500px"
    >
      <v-card class="text-center">
        <v-card-text class="section-to-print">
          <vue-qr

            :size="300"
            :text="!!topicData.id_topic && topicData.id_topic.toString()"
            style="width: 100%; border: 1px solid #ccc;"
          ></vue-qr>
        </v-card-text>
        <v-card-title
          class="justify-center section-to-print pt-0 pb-2"
        >
          {{ topicData.name }} - {{ topicData.id_topic }}
        </v-card-title>
        <v-card-text class="section-to-print text-lg">
          {{ topicData.speaker }}
        </v-card-text>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="6"
              class="pb-0"
            >
              <v-btn
                color="primary"
                block
                @click="handlePrint"
              >
                {{ $t('PrintQr') }}
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-btn
                color="warning"
                block
                outlined
                @click="showQrCode = false"
              >
                {{ $t('Close') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <ConfirmDialog
      v-if="isShowDialogRejectTopic"
      :title="$t('AreYouSure')"
      :content="$t('AreYouSureToRejectTopic')"
      @cancel="isShowDialogRejectTopic = false"
      @ok="handleRejectTopic"
    ></ConfirmDialog>

    <ConfirmDialog
      v-if="isShowDialogApproveTopic"
      :title="$t('AreYouSure')"
      :content="$t('AreYouSureToApproveTopic')"
      @cancel="isShowDialogApproveTopic = false"
      @ok="handleApproveTopic"
    ></ConfirmDialog>
  </div>
</template>
<script>
import ConfirmDialog from '@/components/ConfirmDialog.vue'
import SkillShareService from '@/services/SkillShareService'
import store from '@/store'
import { enrichTopicTime, getTopicStatus, getTopicStatusColor } from '@/utils/skillShareHelper'
import { useRouter } from '@core/utils'
import { computed, onMounted, ref } from '@vue/composition-api'
import VueQr from 'vue-qr'

export default {
  components: {
    VueQr,
    ConfirmDialog,
  },
  setup() {
    const { router } = useRouter()
    const topicId = router.currentRoute.params.id
    const topicData = ref({})
    const showCheckedinList = ref(false)
    const showQrCode = ref(false)
    const checkedInList = ref([])
    const needApproval = computed(() => topicData.value.status_label === 3)
    const isRejecting = ref(false)
    const isApproving = ref(false)
    const isShowDialogRejectTopic = ref(false)
    const isShowDialogApproveTopic = ref(false)
    const canEdit = computed(() => topicData.value.status_label === 2)

    const getTopicData = async () => {
      try {
        const resp = await SkillShareService.getStaffTopicDetail(topicId)

        topicData.value = resp.data.data.topic
        checkedInList.value = resp.data.data.joiners
      } catch {}
    }

    onMounted(async () => {
      await getTopicData()
    })

    const handleRejectTopic = async () => {
      isRejecting.value = true
      try {
        await SkillShareService.rejectTopic(topicId)
        await getTopicData()
        store.commit('snackbar/show', {
          message: 'Reject event successfully',
          type: 'success',
        })
      } catch {
      } finally {
        isRejecting.value = false
      }
    }

    const handleApproveTopic = async () => {
      isApproving.value = true
      try {
        await SkillShareService.approveTopic(topicId)
        await getTopicData()
        store.commit('snackbar/show', {
          message: 'Approve event successfully',
          type: 'success',
        })
      } catch {
      } finally {
        isApproving.value = false
      }
    }

    const handleShowCheckinList = () => {
      showCheckedinList.value = true
      getTopicData()
    }

    const handlePrint = () => {
      try {
        // Print for Safari browser
        document.execCommand('print', false, null)
      } catch {
        window.print()
      }
    }

    return {
      isRejecting,
      isApproving,
      showQrCode,
      showCheckedinList,
      needApproval,

      topicData,
      checkedInList,
      getTopicStatus,
      getTopicStatusColor,
      enrichTopicTime,
      handleRejectTopic,
      handleApproveTopic,
      isShowDialogRejectTopic,
      isShowDialogApproveTopic,
      handleShowCheckinList,
      handlePrint,
      canEdit,
    }
  },
}
</script>

<style lang="scss" scoped>
img {
  max-width: 100%;
  border: 1px solid #ccc;
  display: block !important;
  margin: 0 auto;
}
.section-to-print img {
  width: 100%;
}

@media print {
  body * {
    visibility: hidden !important;
  }
  .section-to-print,
  .section-to-print * {
    visibility: visible !important;
  }
}
</style>
